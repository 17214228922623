var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            "action-collapse": "",
            "show-loading": _vm.busy,
            title: _vm.$t("Perfil"),
          },
        },
        [
          _c(
            "FormulateForm",
            { ref: "form", on: { submit: _vm.saveRole } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          name: "name",
                          type: "text",
                          label: _vm.$t("Nome"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: { type: "switch", label: _vm.$t("Head?") },
                        model: {
                          value: _vm.headProfile,
                          callback: function ($$v) {
                            _vm.headProfile = $$v
                          },
                          expression: "headProfile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: { type: "switch", label: _vm.$t("Diretor?") },
                        model: {
                          value: _vm.directorProfile,
                          callback: function ($$v) {
                            _vm.directorProfile = $$v
                          },
                          expression: "directorProfile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: {
            "action-collapse": "",
            "show-loading": _vm.busy,
            title: _vm.$t("Acessos"),
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      name: "filter",
                      type: "text",
                      placeholder: _vm.$t("Filtrar"),
                    },
                    model: {
                      value: _vm.filter,
                      callback: function ($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("tree", {
                    ref: "treeView",
                    attrs: {
                      data: _vm.treeData,
                      options: _vm.treeOptions,
                      filter: _vm.filter,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("e-crud-actions", {
        attrs: { busy: _vm.busy },
        on: {
          save: _vm.saveRole,
          cancel: function ($event) {
            return _vm.$router.back()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }