<template>
  <div>
    <b-card-actions
      action-collapse
      :show-loading="busy"
      :title="$t('Perfil')"
    >
      <FormulateForm
        ref="form"
        @submit="saveRole"
      >
        <b-row>
          <b-col md="12">
            <FormulateInput
              v-model="name"
              name="name"
              type="text"
              class="required"
              :label="$t('Nome')"
              validation="required"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              v-model="headProfile"
              type="switch"
              :label="$t('Head?')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              v-model="directorProfile"
              type="switch"
              :label="$t('Diretor?')"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </b-card-actions>

    <b-card-actions
      action-collapse
      :show-loading="busy"
      :title="$t('Acessos')"
    >
      <b-row>
        <b-col md="6">
          <FormulateInput
            v-model="filter"
            name="filter"
            type="text"
            :placeholder="$t('Filtrar')"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <tree
            ref="treeView"
            :data="treeData"
            :options="treeOptions"
            :filter="filter"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <e-crud-actions
      :busy="busy"
      @save="saveRole"
      @cancel="$router.back()"
    />
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { alerts } from '@/mixins'
import _ from 'lodash'
import ECrudActions from '@/views/components/ECrudActions.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BCol,
    BRow,
    ECrudActions,
    BCardActions,
  },

  mixins: [alerts],

  data() {
    return {
      isEdit: !!this.$router.currentRoute.params.id,
      busy: false,
      name: null,
      headProfile: false,
      directorProfile: false,
      filter: null,
      treeData: [],
      treeOptions: {
        checkbox: true,
        filter: {
          emptyText: 'Nenhuma opção encontrada',
          matcher(query, node) {
            return new RegExp(query, 'i').test(node.text)
          },
          plainList: false,
          showChildren: true,
        },
      },
    }
  },

  computed: {
    groupedRoles() {
      const groupArr = _.groupBy(this.hubPermissions.telas, 'grupo')
      const sortedArr = Object.keys(groupArr).sort()
      return sortedArr.map(item => ({
        grupo: item,
        telas: groupArr[item],
        active: groupArr[item]
          .map(arr => arr.funcionalidades.filter(f => f.ativo).length)
          .reduce((a, b) => a + b, 0),
        total: groupArr[item].map(arr => arr.funcionalidades.length).reduce((a, b) => a + b, 0),
      }))
    },
  },

  created() {},

  async mounted() {
    try {
      this.busy = true
      const { data: role } = this.isEdit
        ? await this.$http.get(`/api/accessProfiles/${this.$router.currentRoute.params.id}`, {
            params: {
              includePermissions: true,
            },
          })
        : {}
      this.name = role?.name
      this.headProfile = role?.headProfile
      this.directorProfile = role?.directorProfile

      const { results: functionalities } = (
        await this.$http.get('/api/functionalities', { params: { pageSize: 0, pageIndex: 0 } })
      ).data

      const functionalityGroupedByModule = _.groupBy(
        functionalities,
        func => func.module || 'Geral'
      )

      Object.keys(functionalityGroupedByModule).forEach(module => {
        const moduleNode = {
          text: module,
          data: module,
          children: [],
        }
        functionalityGroupedByModule[module].forEach(func => {
          const funcNode = {
            text:
              func.name + (func.resource || func.code ? ` (${func.code ?? func.resource})` : ''),
            data: func,
            children: [],
          }
          moduleNode.children.push(funcNode)
          func.actions.forEach(action => {
            funcNode.children.push({
              text:
                action.name +
                (action.resource || action.code ? ` (${action.code ?? action.resource})` : '') +
                (action.description ? ` (${action.description})` : ''),
              data: { ...action, resource: func.resource },
              state: {
                checked: role
                  ? !!role.permissions.find(perm => perm === `${func.resource}.${action.code}`)
                  : false,
              },
            })
          })
          funcNode.children = _.sortBy(funcNode.children, node => node.text)
        })
        moduleNode.children = _.sortBy(moduleNode.children, node => node.text)
        this.treeData.push(moduleNode)
      })

      this.treeData = _.sortBy(this.treeData, node => node.text)
      this.$refs.treeView.setModel(this.treeData)
    } catch (error) {
      this.showGenericError({ error })
    } finally {
      this.busy = false
    }
  },

  methods: {
    ...mapActions('pages/security/accessProfiles', [
      'fetchAccessProfiles',
      'fetchallAccessProfiles',
    ]),

    async saveRole() {
      this.$refs.form.showErrors()
      if (this.$refs.form.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.busy = true
        const permissions = []
        this.$refs.treeView.model.forEach(module => {
          module.children.forEach(functionality => {
            functionality.children.forEach(action => {
              if (action.states.checked) {
                permissions.push(`${functionality.data.resource}.${action.data.code}`)
              }
            })
          })
        })
        const body = {
          id: this.$router.currentRoute.params.id,
          name: this.name,
          headProfile: this.headProfile,
          directorProfile: this.directorProfile,
          permissions,
        }
        await this.$http({
          url: '/api/accessProfiles',
          method: this.isEdit ? 'PUT' : 'POST',
          data: body,
        })
        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Edição' : 'Inclusão'} Realizada com Sucesso`),
        })
        this.$router.back()
        await this.fetchAccessProfiles()
        await this.fetchallAccessProfiles()
      } catch (error) {
        console.error(error)
        this.showError({ messages: error.message })
      } finally {
        this.busy = false
      }
    },
  },
}
</script>
